/**
 * 链接到房源详情
 * 
 * @Author: Focci
 * @Date: 2025-03-18 14:07:29
 * @Last Modified by: Focci
 * @Last Modified time: 2025-03-18 14:07:29
 */

'use client'

// import PropTypes from 'prop-types'
import useApp from '@hook/useApp'
import { propertyDetail } from '@lib/route/house'
import { useLocale } from 'next-intl'
import { useMemo } from 'react'
import { LinkTo } from './LinkTo'

export default function LinkPropertyDetail({
  children,
  href,
  slug, 
  ...props 
}) {
  const locale = useLocale()
  const { isMobile } = useApp()

  const defaultProps = useMemo(() => {
    const tmp = {
      href: href || propertyDetail(slug, locale),
      target: isMobile ? '_self' : '_blank',
      ...props
    }

    if(!isMobile) {
      tmp['data-noprogress'] = true
    }

    return tmp
  }, [href, isMobile, locale, props, slug])

  return (
    <LinkTo {...defaultProps}>
      {children}
    </LinkTo>
  )
}

// LinkPropertyDetail.propTypes = {
//   className: PropTypes.string,
// }
